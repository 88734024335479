import React, { useEffect, useState } from "react";
import "./FeaturedProducts.css";
import { useNavigate, Link, useParams } from "react-router-dom";
import axios from "axios";

const FeaturedProducts = ({websiteName}) => {
  const navigate = useNavigate();
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.bricksoft.io/api/v1/services/public/${websiteName}`)  // use public endpoint
      .then((response) => {
        setServicesData(response.data.data.services);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }, [websiteName]);  // add websiteName to dependency array


  const handleServiceClick = (service) => {
    navigate(`/service/${service._id}`);
  };


  return (
    <div className="featured-products">
      <h2 className="featured-title">Featured Services</h2>
      <div className="services-container">
        {servicesData.map((service, index) => (
          <div
            key={service._id}
            className="service-card"
            onClick={() => handleServiceClick(service)}
          >
            <img
              src={service.images[0]}
              alt={service.title}
              className="service-image"
            />
            <Link to={`/${websiteName}/service/${service._id}`} className="service-name-link">
              <h3 className="service-name">{service.title}</h3>
            </Link>
            <p className="service-price">{service.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedProducts;
