import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./ImageSlider.css";

const DEFAULT_IMAGE = "https://i.ibb.co/WPPbZPL/1.png";

const ImageSlider = ({
  sliderImages = [],
  captionText = "Browse our services",
  buttonText = "Explore Services",
  imageSlidePosition1,
  imageSlidePosition2,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // If no slider images provided, use default images
  const slides = useMemo(
    () => (sliderImages.length > 0 ? sliderImages : [DEFAULT_IMAGE, DEFAULT_IMAGE]).map((imageUrl) => ({
        image: imageUrl || DEFAULT_IMAGE,
    })),
    [sliderImages]
  );

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  }, [slides]);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      nextSlide();
    }, 10000); // 10 seconds

    return () => {
      clearInterval(slideInterval);
    };
  }, [nextSlide]);

  return (
    <div className="slider-container">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slider-image-wrapper ${
            index === currentSlide ? "active" : ""
          }`}
          style={{
            backgroundImage: `url(${slide.image})`,
            backgroundPosition: `${
              index + 1 === 1
                ? `${imageSlidePosition1}%`
                : `${imageSlidePosition2}%`
            }`,
          }}
        ></div>
      ))}
      <div className="slider-caption">
        <h2>{captionText ? captionText : "This text can be changed 💬"}</h2>
        <button className="slider-shop-all">{buttonText ? buttonText : "I am a button!"}</button>
      </div>
      <button className="slider-prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="slider-next" onClick={nextSlide}>
        &#10095;
      </button>
    </div>
  );
};

export default ImageSlider;
