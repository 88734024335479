import React, { useState, useEffect } from "react";
import "./Header.css";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faShoppingCart,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const Header = ({ customStyles, cartItems }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnnouncementScrolled, setAnnouncementScrolled] = useState(false);
  const [cartChange, setCartChange] = useState(false);
  const { websiteName } = useParams();

  useEffect(() => {
    setCartChange(true);
    const timer = setTimeout(() => setCartChange(false), 500);
    return () => clearTimeout(timer);
  }, [cartItems]);

  const toggleMenu = () => {
    const menu = !menuOpen
      ? document.querySelector(".header-menu-closed")
      : document.querySelector(".header-menu-open");

    if (!menuOpen) {
      menu.style.animation = "slideIn 0.3s forwards";
    } else {
      menu.style.animation = "slideOut 0.3s forwards";
    }

    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleAnnouncementScroll = () => {
    if (window.scrollY > 28) {
      setAnnouncementScrolled(true);
    } else {
      setAnnouncementScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleAnnouncementScroll);
    return () => {
      window.removeEventListener("scroll", handleAnnouncementScroll);
    };
  }, []);

  const {
    announcementBarBgColor,
    announcementBarTextColor,
    announcementBarText,
    navBarBgColor,
    navBarLogoFontSize,
    navBarIconLinkColor,
    navBarMenuLinkColor,
    brandName,
  } = customStyles || {};

  return (
    <>
      <div
        className="announcement-bar"
        style={{ backgroundColor: announcementBarBgColor }}
      >
        <p style={{ color: announcementBarTextColor }}>
          {announcementBarText
            ? announcementBarText
            : "20% Off On Example Store!"}
        </p>
      </div>
      <div className="header-divider"></div>
      <header
        className={`header${isScrolled ? " header-shadow" : ""}`}
        style={{ backgroundColor: navBarBgColor }}
      >
        <nav className="header-nav">
          <div className="hamburger-menu" onClick={toggleMenu}>
            <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
          </div>
          <div className="header-logo">
            <Link
              to={`/`}
              className="header-logo-link"
              style={{ fontSize: navBarLogoFontSize }}
            >
              {brandName ? brandName : "Example"}
            </Link>
          </div>

          <ul
            className={`header-menu-${menuOpen ? "open" : "closed"}`}
            style={{ top: isAnnouncementScrolled ? "45px" : "80px" }}
          >
            <li className="header-menu-item">
              <Link
                to={`/`}
                className="header-menu-link"
                style={{ color: navBarMenuLinkColor }}
              >
                Home
              </Link>
            </li>
            <li className="header-menu-item">
              <Link
                to={`/catalog`}
                className="header-menu-link"
                style={{ color: navBarMenuLinkColor }}
              >
                Catalog
              </Link>
            </li>
            <li className="header-menu-item">
              <Link
                to={`/contact`}
                className="header-menu-link"
                style={{ color: navBarMenuLinkColor }}
              >
                Contact
              </Link>
            </li>
          </ul>
          <ul
            className={`header-menu-desktop`}
            style={{ top: isAnnouncementScrolled ? "45px" : "80px" }}
          >
            <li className="header-menu-item">
              <Link
                to={`/`}
                className="header-menu-link"
                style={{ color: navBarMenuLinkColor }}
              >
                Home
              </Link>
            </li>
            <li className="header-menu-item">
              <Link
                to={`/catalog`}
                className="header-menu-link"
                style={{ color: navBarMenuLinkColor }}
              >
                Catalog
              </Link>
            </li>
            <li className="header-menu-item">
              <Link
                to={`/contact`}
                className="header-menu-link"
                style={{ color: navBarMenuLinkColor }}
              >
                Contact
              </Link>
            </li>
          </ul>
          <div className="header-icons">
            <Link
              to={`/search`}
              className="header-icon-link"
              style={{ color: navBarIconLinkColor }}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>
            <Link to={`/cart`} className="header-icon-link cart-icon-link">
              <div className="cart-icon-container">
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  className="material-icons"
                />
                <span className="cart-items-count">
                  {cartItems.reduce((acc, item) => acc + item.quantity, 0)}
                </span>
              </div>
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;