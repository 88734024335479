import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import "./ProductPage.css";
import { useParams } from "react-router-dom";
import axios from "axios";

const ProductPage = ({ styles = {}, addToCart }) => {
  const {
    addToCartButtonColor,
    addToCartButtonBgColor,
    bookNowButtonColor,
    bookNowButtonBgColor,
  } = styles;

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  const [quantity, setQuantity] = useState(1);
  const [service, setService] = useState(null);
  const { serviceId } = useParams();

  useEffect(() => {
    axios
      .get(`https://api.bricksoft.io/api/v1/services/public/service/${serviceId}`)
      .then((response) => {
        setService(response.data.data.service);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [serviceId]);

  const handleQuantityChange = (change) => {
    const newQuantity = quantity + change;
    if (newQuantity > 0) {
      setQuantity(newQuantity);
    }
  };

  if (!service) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-page">
      <div className="product-image-container">
        <img
          src={service.images[0]}
          alt={service.title}
          className="product-image"
        />
      </div>
      <div className="product-info">
        <h1 className="product-title">{service.title}</h1>
        <p className="product-price">{service.price}</p>
        <div className="quantity-container">
          <button
            className="quantity-button"
            onClick={() => handleQuantityChange(-1)}
          >
            -
          </button>
          <span className="quantity">{quantity}</span>
          <button
            className="quantity-button"
            onClick={() => handleQuantityChange(1)}
          >
            +
          </button>
        </div>
        <div className="product-actions">
          <button
            className="add-to-cart"
            onClick={() =>
              addToCart(
                service._id,
                quantity,
                service.images,
                service.title,
                service.price
              )
            }
            style={{
              color: addToCartButtonColor,
              backgroundColor: addToCartButtonBgColor,
            }}
          >
            Add to Cart
          </button>
          <button
            className="buy-now"
            style={{
              color: bookNowButtonColor,
              backgroundColor: bookNowButtonBgColor,
            }}
          >
            Book Now
          </button>
        </div>
        <div className="product-details">
          <h2>Product Details</h2>
          {parse(decodeHtml(service.description))}
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
