import React from "react";
import "./Cart.css";

const Cart = ({ cart, setCart }) => {
  const updateQuantity = (serviceId, quantity) => {
    if (quantity <= 0) {
      // Remove item from cart
      setCart(cart.filter(item => item.serviceId !== serviceId));
    } else {
      // Update quantity of item
      setCart(cart.map(item => item.serviceId === serviceId
        ? { ...item, quantity }
        : item
      ));
    }
  };

  if (cart.length === 0) {
    return (
      <div className="cart-empty">
        <p>Your cart is empty.</p>
      </div>
    );
  }

  const total = cart.reduce((acc, item) => acc + (item.price * item.quantity), 0);

  return (
    <div className="cart">
      <h2>Shopping Cart</h2>
      {cart.map(item => (
        <div key={item.serviceId} className="cart-item">
          <img src={item.images[0]} alt={item.title} className="cart-item-image" />
          <div className="cart-item-details">
            <p className="cart-item-title">{item.title}</p>
            <input
              type="number"
              min="0"
              value={item.quantity}
              onChange={(e) => updateQuantity(item.serviceId, parseInt(e.target.value))}
              className="cart-item-quantity"
            />
            <p className="cart-item-price">{item.price}</p>
          </div>
        </div>
      ))}
      <div className="cart-total">
        <p>Subtotal ({cart.length} items): ${total}</p>
      </div>
    </div>
  );
};

export default Cart;
