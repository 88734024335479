import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Website from "./components/Website/Website.js";

function getWebsiteName() {
  const host = window.location.host;
  if (host.includes("bricksoft.io") || host.includes("dhruvjyoti.site")) {
    return host.split(".")[0];
  } else {
    return host;
  }
}

function App() {
  const websiteName = getWebsiteName();

  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Website websiteName={websiteName} />} />
      </Routes>
    </Router>
  );
}

export default App;
