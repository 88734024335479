import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">Bricksoft</div>
        <nav className="footer-nav">
          <ul className="footer-menu">
            <li className="footer-menu-item">
              <a href="/" className="footer-menu-link">Home</a>
            </li>
            <li className="footer-menu-item">
              <a href="/shop" className="footer-menu-link">Shop</a>
            </li>
            <li className="footer-menu-item">
              <a href="/about" className="footer-menu-link">About</a>
            </li>
            <li className="footer-menu-item">
              <a href="/contact" className="footer-menu-link">Contact</a>
            </li>
          </ul>
        </nav>
        <div className="footer-copyright">© 2023 Bricksoft. All Rights Reserved.</div>
      </div>
    </footer>
  );
};

export default Footer;
