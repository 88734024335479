import React, { useState, useEffect } from "react";
import { useParams, Route, Routes } from "react-router-dom";
import FeaturedProducts from "../FeaturedProducts/FeaturedProducts";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import ImageSlider from "../Slider/ImageSlider";
import ProductPage from "../ProductPage/ProductPage";
import Cart from "../Cart/Cart.js";
import axios from "axios";
import "../../App.css";

function Website(props) {
  const websiteName = props.websiteName;
  const [websiteData, setWebsiteData] = useState({});
  const [cart, setCart] = useState([]);


  const addToCart = (serviceId, quantity, images, title, price) => {
    const existingItem = cart.find((item) => item.serviceId === serviceId);
    if (existingItem) {
      setCart(
        cart.map((item) =>
          item.serviceId === serviceId
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      );
    } else {
      setCart([...cart, { serviceId, quantity, images, title, price }]);
    }
  };

  useEffect(() => {
    axios
      .get(`https://api.bricksoft.io/api/v1/websites/${websiteName}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data) {
          setWebsiteData(response.data);
        } else {
          console.error("No website data found for user");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch website data:", error);
      });
  }, [websiteName]);

  // Extract customization details from websiteData
  const { headerStyles, slideshowStyles, productPageStyles } = websiteData;

  return (
    <div>
      <Header customStyles={headerStyles} cartItems={cart} />
      <div className="header-content-divider"></div>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <ImageSlider
                sliderImages={slideshowStyles?.sliderImages}
                captionText={slideshowStyles?.captionText}
                buttonText={slideshowStyles?.buttonText}
                imageSlidePosition1={slideshowStyles?.imageBgPosition1}
                imageSlidePosition2={slideshowStyles?.imageBgPosition2}
              />
              <FeaturedProducts websiteName={websiteName}/>
            </>
          }
        />
        <Route
          path="/service/:serviceId"
          element={
            <ProductPage styles={productPageStyles} addToCart={addToCart} />
          }
        />
        <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default Website;
